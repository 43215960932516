import React, { useEffect, useState, useRef } from 'react';

const AnimateShowNumber = ({
                         endNumber = 0,
                         time = 800,
                         withThousand = false,
                         decimals = 2,
                         isPercent = false
                       }) => {
  const [count, setCount] = useState(0);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const easeOut = (progress) => 1 - (1 - progress) ** 3;
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );
    
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    
    return () => {
      if (observer && elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);
  
  useEffect(() => {
    if (isVisible) {
      let start = null;
      
      const animate = (timestamp) => {
        if (!start) start = timestamp;
        const progress = Math.min((timestamp - start) / time, 1);
        const easedProgress = easeOut(progress);
        
        setCount(easedProgress * endNumber);
        
        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };
      
      requestAnimationFrame(animate);
    }
  }, [isVisible, endNumber, time]);
  
  const formattedCount = withThousand ?
    new Intl.NumberFormat('en-US').format(count)
    // count > 1000 ?
    //   (count / 1000).toFixed(decimals).replace(',', '.')
      // :
      // count.toFixed(decimals).replace(',', '.')
    :
    count.toFixed(decimals).replace(',', '.');
  
  return (
    <div ref={elementRef}>
      <span>{formattedCount}{isPercent && '%'}</span>
    </div>
  );
};

export default AnimateShowNumber;