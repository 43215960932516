import $host from './index';

export const getCustomer = async (id) => {
  try {
    const data = await $host.get(`api/profile/get_customer_info/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getCleaner = async (id) => {
  try {
    const data = await $host.get(`api/profile/get_cleaner_info/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAddress = async (id) => {
  try {
    const data = await $host.get(`api/address/get_address_info/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getSubscription = async (id) => {
  try {
    const data = await $host.get(`api/order/get_subscription_info/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getCleaning = async (id) => {
  try {
    const data = await $host.get(`api/order/get_cleaning_info/${id}`);
    console.log("data: ", data);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAgent = async (id) => {
  try {
    const data = await $host.get(`api/agent/get_agent/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAllUsers = async () => {
  try {
    const data = await $host.get(`api/profile/get_all_users`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const getAllOrders = async () => {
  try {
    const data = await $host.get(`api/order/get_all_admin_orders`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const sendNotificationsToUsers = async (dataObj) => {
  try {
    const dataObject = dataObj.cleaningDate && dataObj.cleaningTime ? {
      users: dataObj.users,
      title: dataObj.title,
      description: dataObj.description,
      cleaningDate: dataObj.cleaningDate,
      cleaningTime: dataObj.cleaningTime,
      orderId: dataObj.orderId
    } : {
      users: dataObj.users,
      title: dataObj.title,
      description: dataObj.description,
      orderId: dataObj.orderId
    }
    
    const data = await $host.post(`api/notification/send_notification_to_users`, dataObject);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const addCleanerToOrder = async (dataObj) => {
  try {
    const dataObject = {
      userId: dataObj.userId,
      status: dataObj.status,
      orderId: dataObj.orderId
    }
    console.log("dataObj: ", dataObj);
    const data = await $host.patch(`api/job/update_job/${dataObj.jobId}`, dataObject);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};
