import React, { useEffect, useRef, useState } from 'react';
import Select from "react-dropdown-select";
import calendarSvg from '../../../images/calendar.svg';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from "react-i18next";

const CustomSelectedCalendar = ({ setDate }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState([{
    label: t('all'),
    value: 'all',
  }]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [dateLabel, setDateLabel] = useState(t('calendar'));
  const calendarRef = useRef(null);
  
  const handleSelectChange = (values) => {
    const selectedValue = values[0]?.value;
    const today = new Date();
    let startDate = new Date(2024, 8, 1);
    
    switch (selectedValue) {
      case 'all':
        // startDate = endDate = today;
        setDate([startDate, today]);
        break;
      case 'calendar':
        setDateLabel(t('calendar'));
        setShowCalendar(true);
        break;
      default:
        setDate([today, today]);
    }
    
    setSelectedOption(values);
    // onChange(values);
  };
  
  useEffect(() => {
    function insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode);
    }
    let el = document.createElement("img");
    el.src = calendarSvg;
    el.className = "calendarSvg__icon";
    let dropdownInput = document.querySelector('.dateRange__dropdown .react-dropdown-select-content');
    let imgEl = document.querySelector('.dateRange__dropdown .calendarSvg__icon');
    
    if (dropdownInput && !imgEl) {
      insertAfter(dropdownInput, el);
    }
    handleSelectChange([{
      label: t('all'),
      value: 'all',
    }]);
  }, []);
  
  const handleDateChange = (ranges) => {
    const { selection } = ranges;
    
    // console.log("selection: ", selection);
    setDateRange([selection]);
    
    // setDate([selection.startDate, selection.endDate]);
    setDate([selection.startDate, selection.endDate]);
    
    const startDateUTC = selection.startDate.toISOString();
    const endDateUTC = selection.endDate.toISOString();
    
    const formattedStartDate = formatInTimeZone(startDateUTC, 'Europe/Madrid', 'dd.MM.yyyy');
    const formattedEndDate = formatInTimeZone(endDateUTC, 'Europe/Madrid', 'dd.MM.yyyy');
    const newLabel = `${formattedStartDate} -> ${formattedEndDate}`;
    
    // const formattedStartDate = formatInTimeZone(selection.startDate, 'Europe/Madrid', 'dd.MM.yyyy');
    // const formattedEndDate = formatInTimeZone(selection.endDate, 'Europe/Madrid', 'dd.MM.yyyy');
    // const newLabel = `${formattedStartDate} -> ${formattedEndDate}`;
    
    setDateLabel(newLabel);
    setSelectedOption([{ label: newLabel, value: 'calendar' }]);
    setShowCalendar(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setShowCalendar(false);
      }
    };
    
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);
  
  return (
    <div className="date_range">
      <Select
        loading={false}
        multi={false}
        options={[
          { label: t('all'), value: 'all' },
          { label: dateLabel, value: 'calendar' },
        ]}
        onChange={handleSelectChange}
        values={selectedOption}
        className="dateRange__dropdown"
        color={"#268664"}
        selectAll={false}
      />
      <div
        ref={calendarRef}
      >
        {showCalendar && (
          <DateRangePicker
            className="dateRange__calendar"
            ranges={dateRange}
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            rangeColors={['#4BAF9D']}
            direction="horizontal"
            months={2}
            showSelectionPreview={true}
          />
        )}
      </div>
    </div>
  );
};

export default CustomSelectedCalendar;
