import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import HistoryContext from '../../utils/HistoryContext';
import './Breadcrumbs.scss';
import ellipsisSvg from '../../images/ellipsis.svg';
import { Button, Modal } from "@mui/material";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import checkSvg from '../../images/check.svg';
import { formatDateOptions } from "../../utils/formatDate";
import { deleteSelectedJobs, deleteSelectedOrders, deleteUsers } from "../../http/listsAPI";
import { sendNotificationsToUsers } from "../../http/cardsAPI";

const Breadcrumbs = ({ selectedItems }) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const history = useContext(HistoryContext);
  const modalExportRef = useRef(null);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [selectedExport, setSelectedExport] = useState('CSV');
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const modalNotificationRef = useRef(null);
  const [isOpenManageNotification, setIsOpenManageNotification] = useState(false);
  const [isOpenNewNotification, setIsOpenNewNotification] = useState(false);
  const [heading, setHeading] = useState('');
  const [text, setText] = useState('');
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setModalPosition({ top: e.clientY + 10, left: e.clientX - 100 });
    setIsOpen(true);
  };
  
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose(e);
      }
      if (modalExportRef.current && !modalExportRef.current.contains(e.target)) {
        setIsOpenExport(false);
        setIsOpenRemove(false);
        setIsOpenManageNotification(false);
      }
      if (modalNotificationRef.current && !modalNotificationRef.current.contains(e.target)) {
        setIsOpenNewNotification(false);
        setHeading('');
        setText('');
      }
    };
    
    if (isOpen || isOpenExport || isOpenRemove || isOpenManageNotification || isOpenNewNotification) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isOpenExport, isOpenRemove, isOpenManageNotification, isOpenNewNotification]);
  
  const handleChangeCleanerAction = async (e, action) => {
    e.stopPropagation();
    e.preventDefault();
    try {
        if (action === "export") {
          handleClose(e);
          setIsOpenRemove(false);
          setIsOpenManageNotification(false);
          setIsOpenExport(true);
        } else if (action === "delete") {
          handleClose(e);
          setIsOpenExport(false);
          setIsOpenManageNotification(false);
          setIsOpenRemove(true);
        } else if (action === "notification") {
          handleClose(e);
          setIsOpenExport(false);
          setIsOpenRemove(false);
          setIsOpenManageNotification(true);
        }
    } catch (error) {
      console.log("handleChangeWorkingStatus error: ", error);
    }
  };
  
  const handleExport = (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (selectedItems && selectedItems.rows && selectedItems.rows.length > 0) {
        const rows = selectedItems.rows;
        const fileName = history && history.length > 0 ? `${history[0].split('/')[1]}-${formatDateOptions(new Date())}` : 'generated'
        if (selectedExport === 'CSV') {
          const csvConfig = mkConfig({
            fieldSeparator: ',',
            decimalSeparator: '.',
            useKeysAsHeaders: true,
            fileName: `${fileName}`
          });
          const rowData = rows.map((row) => row.original);
          const csv = generateCsv(csvConfig)(rowData);
          download(csvConfig)(csv);
        } else if (selectedExport === 'PDF') {
          const doc = new jsPDF();
          const tableData = rows.map((row) => Object.values(row.original));
          const tableHeaders = Array.from(
            new Set(
              rows.flatMap(row => Object.keys(row.original))
            )
          );
          
          autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
          });
          
          doc.save(`${fileName}.pdf`);
        }
      }
    } catch (error) {
      console.log("handleChangeWorkingStatus error: ", error);
    }
  }
  
  const handleDeleteItems = async () => {
    try {
      if (selectedItems && selectedItems.ids && selectedItems.ids.length > 0) {
        if (history[0].split('/')[1] === 'cleaners' ||
          history[0].split('/')[1] === 'customers') {
          const result = await deleteUsers(selectedItems.ids);
          
          if (result.status === 200) {
            console.log("delete result users: ", result);
            window.location.reload();
            setLoading(false);
            setIsOpenRemove(false);
          }
        } else if (history[0].split('/')[1] === 'requests') {
          const result = await deleteSelectedOrders(selectedItems.ids);
          
          if (result.status === 200) {
            console.log("delete result orders: ", result);
            window.location.reload();
            setLoading(false);
            setIsOpenRemove(false);
          }
        } else if (history[0].split('/')[1] === 'jobs') {
          console.log("selectedItems.ids: ", selectedItems.ids);

          const result = await deleteSelectedJobs(selectedItems.ids);
          
          if (result.status === 200) {
            console.log("delete result jobs: ", result);
            window.location.reload();
            setLoading(false);
            setIsOpenRemove(false);
          }
        }
      }
    } catch (error) {
      console.log("handleDeleteUsers error: ", error);
    } finally {
      setLoading(false);
    }
  }
  
  const handleUseTemplate = () => {
    setIsOpenManageNotification(false);
    if (selectedItems && selectedItems.ids && selectedItems.ids.length > 0) {
      localStorage.setItem("selectedUserIds", JSON.stringify(selectedItems.ids));
      navigate('/notifications');
    }
  }
  
  const handleCreateNewBtn = () => {
    setIsOpenManageNotification(false);
    setIsOpenNewNotification(true);
  }
  
  const checkIsFormValid = () => {
    return !!(heading && text && selectedItems && selectedItems.ids && selectedItems.ids.length > 0 && !isSending);
  };
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSending(true);
      const date = new Date();
      const cleaningDate = new Date(date.getTime() - (3 * 60 * 60 * 1000)).toISOString();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? '0' + minutes : minutes;
      
      const cleaningTime = `${hours}:${minutes}`;
      
      const dataObj = {
        users: selectedItems.ids,
        title: heading,
        description: text,
        cleaningDate: cleaningDate,
        cleaningTime: cleaningTime,
      }
      
      if (checkIsFormValid()) {
        const result = await sendNotificationsToUsers(dataObj);
        console.log("result1: ", result);
        if (result && result.status === 201) {
          setHeading('');
          setText('');
          setIsSending(false);
          setIsOpenNewNotification(false);
        }
      }
    } catch (error) {
      console.log("sendNotificationsToUsers error: ", error);
    } finally {
      setIsSending(false);
    }
  }
  
  return (
    <div className="breadcrumbs">
      {history.map((path, index) => {
        const link = path.split('/')[1].replace(/_/g, ' ');
        const linkName = link.charAt(0).toUpperCase() + link.slice(1);
        return (
          <span className="breadcrumbs__item" key={index}>
            {index > 0 && (
              <svg
                className="breadcrumbs__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M9 19L15 13L9 7" stroke="#D8D8D8" strokeLinecap="round" />
              </svg>
            )}
            <NavLink to={`${path}`}>{t(linkName.toLowerCase())}</NavLink>
          </span>
        );
      })}
      <div
        className="breadcrumbs__add_menu"
        onClick={handleOpen}
      >
        <img src={ellipsisSvg} alt="ellipsis" />
      </div>
      <Modal
        open={isOpen}
        onClose={(e) => handleClose(e)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={"breadcrumbsModal__modal"}
      >
        <div
          className={"breadcrumbsModal__content"}
          style={{
            top: `${modalPosition.top + 10}px`,
            left: `${modalPosition.left - 285}px`,
          }}
        >
          <div className={"breadcrumbsModal__options_title"}>
            <span>Options</span>
          </div>
          <div className={"breadcrumbsModal__buttons"}>
            {
              history &&
              history.length > 0 &&
                (history[0].split('/')[1] === 'cleaners' ||
                  history[0].split('/')[1] === 'customers') ? (
                  <Button
                    className={"breadcrumbsModal__button"}
                    onClick={(e) => handleChangeCleanerAction(e, 'notification')}
                  >
                    <span>Send notification</span>
                  </Button>
                ) : null
            }
            <Button
              className={"breadcrumbsModal__button"}
              onClick={(e) => handleChangeCleanerAction(e, 'delete')}
            >
              <span>Delete</span>
            </Button>
            <Button
              className={"breadcrumbsModal__button"}
              onClick={(e) => handleChangeCleanerAction(e, 'export')}
            >
              <span>Export</span>
            </Button>
          </div>
        </div>
      </Modal>
      <div className={`modal  ${(isOpenExport || isOpenRemove || isOpenManageNotification) ? 'active' : ''}`}>
        <div className={`modalRef export`} ref={modalExportRef}>
          <div className="modal__close" onClick={() => {
              setIsOpenExport(false);
              setIsOpenRemove(false);
            }}
          >
            <svg className="close_icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M22.0712 22.0709L7.9291 7.92881" stroke="#268664" strokeLinecap="round" />
              <path d="M22.0709 7.92881L7.92876 22.0709" stroke="#268664" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal__title brdc">
            <h2 className="text">{t(isOpenExport ? 'export' : isOpenManageNotification ? 'manageNotification' : 'removeDescription')}</h2>
          </div>
          {
            isOpenExport ? (
              <div className="modal__select_buttons">
                <button
                  className={`modal__select_button_item ${selectedExport === 'CSV' ? 'active' : ''}`}
                  onClick={() => setSelectedExport('CSV')}
                >
                  <img src={checkSvg} alt="check" />
                  <span>CSV</span>
                </button>
                <button
                  className={`modal__select_button_item ${selectedExport === 'PDF' ? 'active' : ''}`}
                  onClick={() => setSelectedExport('PDF')}
                >
                  <img src={checkSvg} alt="check" />
                  <span>PDF</span>
                </button>
              </div>
            ) : (
              <div>
              </div>
            )
          }
          {
            isOpenExport ? (
              <div className="modal__buttons">
                <button
                  className={`btn cancel`}
                  onClick={() => setIsOpenExport(false)}
                >
                  {t('cancel')}
                </button>
                <button
                  className={`btn`}
                  onClick={(e) => handleExport(e)}
                >
                  {t('export')}
                </button>
              </div>
            ) : isOpenManageNotification ? (
              <div className="modal__buttons">
                <button
                  className={`btn cancel`}
                  onClick={handleUseTemplate}
                >
                  {t('useTemplate')}
                </button>
                <button
                  className={`btn`}
                  onClick={handleCreateNewBtn}
                >
                  {t('createNew')}
                </button>
              </div>
            ) : (
              <div className="modal__buttons">
                {
                  loading ? (
                    <div className="spinner spinner_small"></div>
                  ) : (
                    <button
                      className={`btn cancel`}
                      onClick={handleDeleteItems}
                    >
                      {t('yesWantBtn')}
                    </button>
                  )
                }
                <button
                  className={`btn`}
                  onClick={(e) => setIsOpenRemove(false)}
                >
                  {t('no')}
                </button>
              </div>
            )
          }
        </div>
      </div>
      
      <div className={`modal ${isOpenNewNotification ? 'active' : ''}`}>
        <div className={`modalRef`} ref={modalNotificationRef}>
          <div className="modal__close" onClick={() => setIsOpenNewNotification(false)}>
            <svg className="close_icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M22.0712 22.0709L7.9291 7.92881" stroke="#268664" strokeLinecap="round" />
              <path d="M22.0709 7.92881L7.92876 22.0709" stroke="#268664" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal__title">
            <h2 className="text">{t('manageNotification')}</h2>
          </div>
          <form className={`form auth-form ${checkIsFormValid() ? 'valid' : 'invalid'}`} onSubmit={handleFormSubmit}>
            <div className="modalForm__fields">
              <div className={'modalForm__field-wrap'}>
                <label htmlFor="notifications-heading" className="modalForm__label">
                  {t('heading')}
                </label>
                <input
                  id="notifications-heading"
                  type="text"
                  className={`modalForm__input ${!heading ? 'invalid-field' : ''}`}
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                  autoComplete="off"
                />
              </div>
              
              <div className={'modalForm__field-wrap'}>
                <label htmlFor="notifications-text" className="modalForm__label">
                  {t('text')}
                </label>
                <input
                  id="notifications-text"
                  type="text"
                  className={`modalForm__input ${!text ? 'invalid-field' : ''}`}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            {isSending ? (
              <div className="spinner spinner_small"></div>
            ) : (
              <button className={`modalForm__btn ${checkIsFormValid() ? '' : 'inactive'}`} type="submit">
                <span>{t('send')}</span>
              </button>
            )}
          </form>
        </div>
      </div>
    
    </div>
  );
};

export default Breadcrumbs;
