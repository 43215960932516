import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../Accounting.scss';
import { ColorSwitch } from "../../../Market/Market";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { getMetricsCustomers } from "../../../../http/pricingAPI";
import { setAccountingMetricsCustomers } from "../../../../store/actions/listActions";

const CustomersMetrics = ({ dates }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const accountingMetricsCustomers = useSelector((state) => state.list.accountingMetricsCustomers);
  const [data, setData] = useState({});
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const dispatch = useDispatch();
  const itemWrapRef = useRef(null);
  
  const calculateSelectedPeriod = () => {
    if (!dates || dates.length === 0) return 'custom';
    
    const startDates = dates.map((date) => new Date(date.startDate));
    const endDates = dates.map((date) => new Date(date.endDate));
    const minDate = new Date(Math.min(...startDates));
    const maxDate = new Date(Math.max(...endDates));
    
    const dayDifference = Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24));
    
    if (dayDifference === 0) return 'today';
    if (dayDifference === 6) return 'week';
    if (dayDifference >= 29 && dayDifference <= 31) return 'month';
    return 'custom';
  };
  
  const generateXAxisLabels = (period, dates) => {
    switch (period) {
      case 'today':
        return ['Today'];
      case 'week':
        return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      case 'month':
        return Array.from({ length: 30 }, (_, i) => `${i + 1}`);
      case 'custom':
        if (dates.length < 2) return [];
        
        let startDate = dates[0];
        let endDate = dates[1];
        console.log("--startDate: ", startDate);
        console.log("--endDate: ", endDate);
        
        const dateArray = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= endDate) {
          dateArray.push(currentDate.toLocaleDateString());
          currentDate.setDate(currentDate.getDate() + 1);
        }
        
        return dateArray;
      default:
        return [];
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      console.log("dates: ", dates);
      
      if (dates && dates.length > 1) {
        let startDate = new Date(dates[0]);
        let endDate = new Date(dates[1]);
        const result = await getMetricsCustomers(startDate, endDate);
        
        if (result.status === 200) {
          console.log("result: ", result);
          dispatch(setAccountingMetricsCustomers(result));
          
          setData({
              customers: result.data.clients,
              newCustomers: result.data.newClients,
              activeCustomers: result.data.activeClients
            });
          if (result.data.dates && result.data.dates.length > 0) {
            setXAxisLabels(result.data.dates);
          } else {
            const selectedPeriod = calculateSelectedPeriod();
            console.log("xAxisLabel: ", generateXAxisLabels(selectedPeriod, dates));
            setXAxisLabels(generateXAxisLabels(selectedPeriod, dates));
          }

          setLoading(false);
        }
        // const resultData = {
        //   customers: [100, 105, 110, 115, 80, 125, 130, 100],
        //   newCustomers: [5, 7, 3, 6, 8, 5, 4, 7],
        //   activeCustomers: [90, 92, 94, 91, 89, 88, 85, 90]
        // }
        
      }
      
      /*const result = await getMetricsCustomers();
      
      if (result.status === 200) {
        console.log("result: ", result);
        // dispatch(setTamAction(result.data.tam));
        setLoading(false);
        
        xAxis={{
                    labels: xAxisLabels && xAxisLabels.length > 0 ? xAxisLabels : ['10/10/2024', '11/10/2024'],
                  }}
      }*/
    };
    
    fetchData().then(() => setLoading(false));
  }, [dates]);
  
  return (
    <div className="customersMetrics">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="charts__wrap">
          {
            data && xAxisLabels && xAxisLabels.length > 0 && (
              <div className="charts__item" ref={itemWrapRef}>
                <LineChart
                  colors={["#4BAF9D", "rgba(43, 182, 115, 0.50)", "#A7D2C7"]}
                  xAxis={[
                    {
                      data: xAxisLabels && xAxisLabels.length > 0 ? xAxisLabels : ['10/10/2024', '11/10/2024'],
                      scaleType: 'band',
                    }
                  ]}
                  sx={{
                        [`.MuiChartsAxis-directionX.MuiChartsAxis-bottom .MuiChartsAxis-tickLabel`]: {
                          // Move the y-axis label with CSS
                          // transform: 'rotate(-60deg) translateY(50px)',
                        },
                  }}
                  series={[
                    { label: t('customers'), curve: "linear", data: data.customers },
                    { label: t('newCustomers'), curve: "linear", data: data.newCustomers },
                    { label: t('activeCustomers'), curve: "linear", data: data.activeCustomers },
                  ]}
                  slotProps={{ legend: { hidden: true } }}
                >
                  <ColorSwitch />
                </LineChart>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

export default CustomersMetrics;
