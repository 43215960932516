import React from 'react';
import { NavLink } from "react-router-dom";
import linkSvg from '../../../images/link.svg';

const ScheduleItem = ({ title, value, link, isLoading }) => {
  return (
    <div className="scheduleItem">
      <div className="scheduleItem__title">
        <span>{title}</span>
      </div>
      <div className="scheduleItem__value">
        {
          isLoading ? (
            <div className="spinner spinner_small"></div>
          ) : (
            <span>{value}</span>
          )
        }
      </div>
      <div className="scheduleItem__link">
        <NavLink to={`/${link}`}>
          <img src={linkSvg} alt="link icon" />
        </NavLink>
      </div>
    </div>
  );
};

export default ScheduleItem;