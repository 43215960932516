import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDashboardDailySummary } from "../../../http/pricingAPI";
import { getJobCols, requestCols } from "../../../constants/tableColumns";
import ListTable from "../../Lists/ListTable/ListTable";
import i18n from "../../../utils/i18n";

const weekDaysEn = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const weekDaysEs = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const CalendarItemDetails = () => {
  const { t } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  let { date } = useParams();
  const [expandedMain, setExpandedMain] = useState({
    'requests': false,
    'jobs': false,
    'cleaners': false,
  });
  const [loading, setLoading] = useState(true);
  const modalRequestsRef = useRef(null);
  const modalJobsRef = useRef(null);
  const [isOpenRequests, setIsOpenRequests] = useState(false);
  const [isOpenJobs, setIsOpenJobs] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [requestsData, setRequestsData] = useState([]);
  const [quickRequestsData, setQuickRequestsData] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [quickJobsData, setQuickJobsData] = useState([]);
  const [cleanersData, setCleanersData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getDashboardDailySummary(new Date(date));
      if (result && result.status === 200) {
        console.log("result.data: ", result.data);
        setTableData(result.data.tableData);
        setQuickRequestsData(result.data.quickRequests);
        setQuickJobsData(result.data.quickJobs);
        setCleanersData(result.data.cleaners);
        setRequestsData(result.data.fullRequests);
        setJobsData(result.data.fullJobs);
        // setCalendarData(result.data.weeklyStats);
      } else {
        // setCalendarData([]);
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, [date]);
  
  const handleRequestsItemPress = (e) => {
    e.stopPropagation();
    setIsOpenRequests(true);
  }
  
  const handleJobsItemPress = (e) => {
    e.stopPropagation();
    setIsOpenJobs(true);
  }
  
  const columns = useMemo(
    () => [
      {
        header: t("requests"),
        accessorKey: "requests",
        size: 350,
        Cell: ({ cell, row }) => (
          <a
            className={'link'}
            onClick={(e) => handleRequestsItemPress(e)}
          >
            {cell.getValue()}
          </a>
        ),
      },
      {
        header: t("jobs"),
        accessorKey: "jobs",
        size: 350,
        Cell: ({ cell, row }) => (
          <a
            className={'link'}
            onClick={(e) => handleJobsItemPress(e)}
          >
            {cell.getValue()}
          </a>
        ),
      },
      {
        header: t("cleaners"),
        accessorKey: "cleaners",
        size: 350,
      },
    ],
    [t]
  );
  
  const toggleCategory = (category) => {
    setExpandedMain((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  const closeModalRequests = () => {
    setIsOpenRequests(false);
  };
  
  const closeModalJobs = () => {
    setIsOpenJobs(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRequestsRef.current && !modalRequestsRef.current.contains(e.target)) {
        closeModalRequests();
      }
      if (modalJobsRef.current && !modalJobsRef.current.contains(e.target)) {
        closeModalJobs();
      }
      
    };
    
    if (isOpenRequests || isOpenJobs) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenRequests, isOpenJobs]);
  
  const requestsColumns = useMemo(() => requestCols, []);
  const jobsColumns = useMemo(() => getJobCols(), []);
  
  return (
    <div className="calendarItemDetails">
      <h2 className="calendarItemDetails__title">{language === 'es' ? weekDaysEs[new Date(date).getDay()] : weekDaysEn[new Date(date).getDay()]}, {new Date(date).getDate()} {new Date(date).toLocaleString(language, { month: 'long' })}</h2>
      {
        loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <div className="data__table-wrap">
              <ListTable data={tableData} columns={columns} isClickable={false} isSelectable={false} />
            </div>
            {/*{Object.keys(expandedMain).map((category) => (
              <div key={category} className={`block ${expandedMain[category] ? 'expanded' : ''}`}>
                <div className={`card__title-wrap ${expandedMain[category] && category && category.replace(/[-_\s](.)/g, (_, c) => c.toUpperCase())}`}>
                  <div className="card__title-item" onClick={() => toggleCategory(category)}>
                    <svg
                      className={expandedMain[category] ? 'arrow' : 'arrow rotated'}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path d="M6 14L12 8L18 14" stroke="#268664" strokeLinecap="round" />
                    </svg>
                    <h2 className="card__title">{t(category)}</h2>
                  </div>
                </div>
                
                {
                  expandedMain[category] &&
                  category === 'requests' ? (
                    <div className="quickRequests">
                      {
                        quickRequestsData &&
                        quickRequestsData.length > 0 ?
                        quickRequestsData.map((quick, index) => (
                          <button
                            key={`${quick} - ${index}`}
                            className="quickRequests__item"
                            onClick={handleRequestsItemPress}
                          >
                            <span>{quick.time}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M9 5L15 11L9 17" stroke="black" strokeLinecap="round"/>
                            </svg>
                          </button>
                        ))
                          :
                          <span>{t('noRequestsThisDay')}</span>
                      }
                    </div>
                  ) : expandedMain[category] &&
                  category === 'jobs' ? (
                    <div className="quickRequests">
                      {
                        quickJobsData &&
                        quickJobsData.length > 0 ?
                        quickJobsData.map((job, index) => (
                          <button
                            key={`${job} - ${index}`}
                            className="quickRequests__item"
                            onClick={handleJobsItemPress}
                          >
                            <span>{job.time}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M9 5L15 11L9 17" stroke="black" strokeLinecap="round"/>
                            </svg>
                          </button>
                        ))
                          :
                          <span>{t('noJobsThisDay')}</span>
                      }
                    </div>
                  ) : expandedMain[category] &&
                    category === 'cleaners' ? (
                    <div className="quickRequests">
                      {
                        cleanersData &&
                        cleanersData.length > 0 ?
                        cleanersData.map((item, index) => (
                          <button key={`${item} - ${index}`} className="quickRequests__item">
                            <div className="quickRequests__item_times">
                              <span className="title">{item.cleanerInfo}: {}</span>
                              {
                                item.cleanerWorkingHours && item.cleanerWorkingHours.length > 0 &&
                                item.cleanerWorkingHours.map((times, indx) => (
                                  <>
                                    {
                                      times && times.length > 0 &&
                                      <span className="times">{times.join(', ')}</span>
                                    }
                                  </>
                                ))
                              }
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M9 5L15 11L9 17" stroke="black" strokeLinecap="round"/>
                            </svg>
                          </button>
                        ))
                          :
                          <span>{t('noCleanersTimesThisDay')}</span>
                      }
                    </div>
                  ) : null
                }
              </div>
            ))
            }*/}
            
            <div className={`modal ${isOpenRequests ? 'active' : ''}`}>
              <div className={`modalRef`} ref={modalRequestsRef}>
                <div className="modal__close" onClick={closeModalRequests}>
                  <svg className="close_icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M22.0712 22.0709L7.9291 7.92881" stroke="#268664" strokeLinecap="round" />
                    <path d="M22.0709 7.92881L7.92876 22.0709" stroke="#268664" strokeLinecap="round" />
                  </svg>
                </div>
                <div className="modal__title">
                  <h2 className="text">{t('requests')}</h2>
                </div>
                {
                  requestsData && requestsData.length > 0 ? (
                    <div className="modal__content">
                      <div className="data__table-wrap">
                        <ListTable data={requestsData} columns={requestsColumns} isClickable={true} isSelectable={false} />
                      </div>
                    </div>
                  ) : null
                }
                
                <button className={`btn`} onClick={closeModalRequests}>
                  <span>{t('done')}</span>
                </button>
              </div>
            </div>
            
            <div className={`modal ${isOpenJobs ? 'active' : ''}`}>
              <div className={`modalRef`} ref={modalJobsRef}>
                <div className="modal__close" onClick={closeModalJobs}>
                  <svg className="close_icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M22.0712 22.0709L7.9291 7.92881" stroke="#268664" strokeLinecap="round" />
                    <path d="M22.0709 7.92881L7.92876 22.0709" stroke="#268664" strokeLinecap="round" />
                  </svg>
                </div>
                <div className="modal__title">
                  <h2 className="text">{t('jobs')}</h2>
                </div>
                {
                  jobsData && jobsData.length > 0 ? (
                    <div className="modal__content">
                      <div className="data__table-wrap">
                        <ListTable data={jobsData} columns={jobsColumns} isClickable={true} isSelectable={false} />
                      </div>
                    </div>
                  ) : null
                }
                
                <button className={`btn`} onClick={closeModalJobs}>
                  <span>{t('done')}</span>
                </button>
              </div>
            </div>
            
          </>
        )
      }
    </div>
  );
};

export default CalendarItemDetails;