import React, { useEffect } from 'react';
import { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { format, subMonths } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

const PdfMonthInvoiceTemplate = ({ data = [] }) => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const prevMonth = subMonths(new Date(), 1);

	useEffect(() => {
		console.log("data: ", data);
	}, [data]);
	
	const numFormat = (num) => {
		return new Intl.NumberFormat('en-US').format(num);
	}

	return (
		<div id="pdfMonthInvoiceTemplate" className="pdfMonthInvoiceTemplate" {...css({
			backgroundColor: 'rgba(40,134,101,0.5)',
			width: '210mm',
			minHeight: '297mm',
			marginLeft: 'auto',
			marginRight: 'auto',
			boxSizing: 'border-box',
			padding: 16
		})}>
			<div className="pdfMonthInvoiceTemplate__content">
				<div className="pdfMonthInvoiceTemplate__content_title">
					<h1>SDL</h1>
					<p>{t('serviceInvoice')}</p>
				</div>
				<div className="pdfMonthInvoiceTemplate__content_info">
					<div className="pdfMonthInvoiceTemplate__content_info_left">
						<span>{format(new Date(), 'LLL d, yyyy', { locale: language === 'en' ? enUS : es })}</span>
						<span>{t('invoiceNr')}: 1</span>
						<div className="pdfMonthInvoiceTemplate__content_info_left_item">
							<span>{t('stayhost')}</span>
							<span>Urb. La Alcazaba s/n. Bajo 1-2 29660 Marbella</span>
						</div>
					</div>
					<div className="pdfMonthInvoiceTemplate__content_info_right">
						<span>First Luxury Realty Marbella, S.L</span>
						<span>CIF/DNI: B72522485</span>
						<span>{t('socialSecurity')}: 29 147551723</span>
					</div>
				</div>
				<div className="pdfMonthInvoiceTemplate__content_data">
					<div className="pdfMonthInvoiceTemplate__content_data_title">
						<span>{t('description')}</span>
						<span>{t('rate')}</span>
						<span>{t('hrs')}</span>
						<span>{t('total')}</span>
					</div>
					<div className="pdfMonthInvoiceTemplate__content_data_table">
						<div className="pdfMonthInvoiceTemplate__content_data_table_period">
							<span>{t('cleaningServices')}</span>
							<span>{format(prevMonth, 'LLLL yyyy', { locale: language === 'en' ? enUS : es })}</span>
						</div>
						<span>€20</span>
						<span>{new Date(prevMonth).getMonth() === 10 ? 81 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0)}</span>
						<span>€{numFormat(new Date(prevMonth).getMonth() === 10 ? 81 * 20 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20)}</span>
					</div>
					<div className="pdfMonthInvoiceTemplate__content_data_total">
							<span>{t('total')}:</span>
						<span>€{new Date(prevMonth).getMonth() === 10 ? numFormat(81 * 20) : numFormat(data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20)}</span>
					</div>
					<div className="pdfMonthInvoiceTemplate__content_data_footer">
						<div className="pdfMonthInvoiceTemplate__content_data_footer_left">
							<span>Carretera de Cadiz, km 176, Centro de Negocios Oasis, local 9, 29602, Marbella (Málaga)</span>
							<span>+34 670 61 95 95</span>
							<span>info@sdl24.es</span>
						</div>
						<div className="pdfMonthInvoiceTemplate__content_data_footer_right">
							<div className="pdfMonthInvoiceTemplate__content_data_footer_right_item">
								<span>{t('subtotal')}</span>
								<span>€{numFormat(new Date(prevMonth).getMonth() === 10 ? 81 * 20 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20)}</span>
							</div>
							<div className="pdfMonthInvoiceTemplate__content_data_footer_right_item">
								<span>{t('vat')} 21%</span>
								<span>€{numFormat((new Date(prevMonth).getMonth() === 10 ? 81 * 20 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20) * 0.21)}</span>
							</div>
							<div className="pdfMonthInvoiceTemplate__content_data_footer_right_item">
								<span>{t('total')}</span>
								<span>€{numFormat((new Date(prevMonth).getMonth() === 10 ? 81 * 20 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20) * 1.21)}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="pdfMonthInvoiceTemplate__content_page">
					<span>1/2</span>
				</div>
			</div>
			
			<div className="pdfMonthInvoiceTemplate__content">
				<div className="pdfMonthInvoiceTemplate__content_title">
					<h1>SDL</h1>
					<p>{t('serviceInvoice')}</p>
				</div>
				<div className="pdfMonthInvoiceTemplate__content_data">
					<div className="pdfMonthInvoiceTemplate__content_data_title second">
						<span>{format(prevMonth, 'LLL yyyy', { locale: language === 'en' ? enUS : es })}</span>
						<span>{t('property')}</span>
						<span>{t('address')}</span>
						<span>{t('hours')}</span>
					</div>
					<div className="pdfMonthInvoiceTemplate__content_data_table second">
						
						{
							data.length > 0 && data.map(item => {
								if (item.original) {
									return (
										<div key={item.id} className="pdfMonthInvoiceTemplate__content_data_table_item">
											<span>{item.original.date.substring(0, item.original.date.indexOf("."))}</span>
											<span>{item.original.property}</span>
											<span>{item.original.fullAddress}</span>
											<span>{item.original.shHours}</span>
										</div>
									)
								} else {
									return null
								}
							})
						}
						
						{/*<div className="pdfMonthInvoiceTemplate__content_data_table_period">
							<span>{t('cleaningServices')}</span>
							<span>{format(prevMonth, 'LLLL yyyy', { locale: language === 'en' ? enUS : es })}</span>
						</div>
						<span>€20</span>
						<span>{new Date(prevMonth).getMonth() === 10 ? 81 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0)}</span>
						<span>€{numFormat(new Date(prevMonth).getMonth() === 10 ? 81 * 20 : data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) * 20)}</span>*/}
					</div>
					<div className="pdfMonthInvoiceTemplate__content_data_total second">
						<div className="pdfMonthInvoiceTemplate__content_data_total_item">
							<span>{t('totalHrs')}</span>
							<span>{numFormat(data.reduce((accumulator, current) => accumulator + current.original.shHours, 0))}</span>
						</div>
						<div className="pdfMonthInvoiceTemplate__content_data_total_item">
							<span>{t('freeHrs')}</span>
							<span>{new Date(prevMonth).getMonth() === 10 ? 19 : 0}</span>
						</div>
						<div className="pdfMonthInvoiceTemplate__content_data_total_item">
							<span>{t('invoiced')}</span>
							<span>{
								numFormat(data.reduce((accumulator, current) => accumulator + current.original.shHours, 0)) > 19 ?
									numFormat(data.reduce((accumulator, current) => accumulator + current.original.shHours, 0) - 19)
									:
									0}</span>
						</div>
					</div>
				</div>
				<div className="pdfMonthInvoiceTemplate__content_page">
					<span>2/2</span>
				</div>
			</div>
		</div>
	);
};

export default PdfMonthInvoiceTemplate;