import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable } from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { addCashflowRow, getCashflow, updateCashflow, deleteCashflowRow } from "../../../../../http/listsAPI";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';

type RowData = {
	[key: string]: string | number;
	category: string;
};

const CashflowTable = () => {
	const { t } = useTranslation();

	const [data, setData] = useState<RowData[]>([]);
	const [loading, setLoading] = useState(false);
	const [validationErrors, setValidationErrors] = useState<
		Record<string, string | undefined>
	>({});
	const [isLoadingSave, setIsLoadingSave] = useState(false);

	const columns = useMemo<MRT_ColumnDef<RowData>[]>(
		() => [
			{
				accessorKey: 'category',
				header: 'Category',
				size: 250,
			},
			{
				accessorKey: '11.2024',
				header: '11.2024',

			},
			{
				accessorKey: '12.2024',
				header: '12.2024',
			},
			{
				accessorKey: '1.2025',
				header: '1.2025',
			},
			{
				accessorKey: '2.2025',
				header: '2.2025',
			},
			{
				accessorKey: '3.2025',
				header: '3.2025',
			},
			{
				accessorKey: '4.2025',
				header: '4.2025',
			},
			{
				accessorKey: '5.2025',
				header: '5.2025',
			},
			{
				accessorKey: '6.2025',
				header: '6.2025',
			},
			{
				accessorKey: '7.2025',
				header: '7.2025',
			},
			{
				accessorKey: '8.2025',
				header: '8.2025',
			},
			{
				accessorKey: '9.2025',
				header: '9.2025',
			},
			{
				accessorKey: '10.2025',
				header: '10.2025',
			},
			{
				accessorKey: '11.2025',
				header: '11.2025',
			},
			{
				accessorKey: '12.2025',
				header: '12.2025',
			},
		],
		[],
	);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const result: any = await getCashflow();
				if (result && result.status === 200) {
					console.log("getCashflow data: ", result.data);
					setData(result.data.cashflow || []);
				} else {
					setData([]);
				}
			} catch (error) {
				console.error("Error fetching data: ", error);
				setData([]);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [isLoadingSave]);

	/*const handleAddRow = async () => {
		const newRow: RowData = {
			category: 'Net income',
			'11.2024': 0,
			'12.2024': 0,
			'1.2025': 0,
			'2.2025': 0,
			'3.2025': 0,
			'4.2025': 0,
			'5.2025': 0,
			'6.2025': 0,
			'7.2025': 0,
			'8.2025': 0,
			'9.2025': 0,
			'10.2025': 0,
			'11.2025': 0,
			'12.2025': 0,
		};

		try {
			console.log("Adding new row: ", newRow);
			const response: any = await addCashflowRow(newRow);
			console.log("add response: ", response);
			// setData((prevData) => [...prevData, response.data]);
		} catch (error) {
			console.error('Ошибка добавления строки:', error);
		}
	};*/

	const handleSaveItems = useCallback(async () => {
		try {
			setIsLoadingSave(true);
			if (Object.values(validationErrors).some((error) => !!error)) return;

			await updateCashflow(data);
		} catch (e) {
			console.log("handleSaveItems error: ", e);
		} finally {
			setIsLoadingSave(false);
		}
	}, [data, validationErrors]);

	const handleSaveCell = (cell: MRT_Cell<RowData>, row: any, event: any) => {
		const updatedValue = parseFloat(event.target.value || '0');
		const [month, year] = cell.column.id.split('.');
		setData((prevData) =>
			prevData.map((item: any, index) => {
				if (index === row.index) {
					return {
						...item,
						[month]: {
							...item[month],
							[year]: updatedValue,
						},
					};
				}
				return item;
			}));
	};

	/*const handleCreateRow: MRT_TableOptions<RowData>['onCreatingRowSave'] = async ({ values, table }) => {
		// await createUser(values);
		console.log("values: ", values);
		const newRow: RowData = {
			category: values.category,
			'11.2024': 0,
			'12.2024': 0,
			'1.2025': 0,
			'2.2025': 0,
			'3.2025': 0,
			'4.2025': 0,
			'5.2025': 0,
			'6.2025': 0,
			'7.2025': 0,
			'8.2025': 0,
			'9.2025': 0,
			'10.2025': 0,
			'11.2025': 0,
			'12.2025': 0,
		};
		table.setCreatingRow(null);
	};*/

	const handleCreateRow: MRT_TableOptions<RowData>['onCreatingRowSave'] = async ({ values, table }) => {
		const newRow: any = {
			...values,
			...Object.keys(values).reduce((acc, key) => {
				acc[key] = values[key] === undefined ? 0 : values[key];
				return acc;
			}, {} as Record<string, any>),
		};

		console.log("New row with defaults:", newRow);

		try {
			setIsLoadingSave(true);
			await addCashflowRow(newRow);
		} catch (e) {
			console.log("addCashflowRow error: ", e);
		} finally {
			setIsLoadingSave(false);
			table.setCreatingRow(null);
		}
	};

	const openDeleteConfirmModal = async (row: MRT_Row<RowData>) => {
		if (window.confirm('Are you sure you want to delete this item?')) {
			// deleteUser(row.original.id); deleteCashflowRow
			try {
				setIsLoadingSave(true);
				await deleteCashflowRow(row.original._id);
			} catch (e) {
				console.log("deleteCashflowRow error: ", e);
			} finally {
				setIsLoadingSave(false);
			}
		}
	};

	return (
		<div className="cashflow">
			{/*<button
				onClick={handleAddRow}
				style={{
					marginBottom: '10px',
					padding: '10px 20px',
					backgroundColor: '#4CAF50',
					color: 'white',
					border: 'none',
					borderRadius: '5px',
					cursor: 'pointer',
				}}
			>
				Add Row
			</button>*/}
			{loading ? (
				<div className="spinner"></div>
			) : (
				<div className="data cashflow">
					<div className="data__table-wrap">
						<MaterialReactTable
							// table={table}

							columns={columns}
							data={data}
							// createDisplayMode={'row'}
							editDisplayMode={'cell'}
							enableEditing={true}
							enableRowActions={true}
							getRowId={(row: any) => row.id}
							manualPagination={true}
							enablePagination={false}
							muiEditTextFieldProps={({ cell, row }: any) => ({
								onBlur: (event: any) => {
									handleSaveCell(cell, row, event);
								},
								variant: 'outlined',
							})}
							renderBottomToolbarCustomActions={({ table }) => (
								<Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
									<Button
										color="success"
										variant="contained"
										onClick={handleSaveItems}
										disabled={
											Object.keys(data).length === 0 ||
											Object.values(validationErrors).some((error) => !!error)
										}
									>
										{isLoadingSave ? <CircularProgress color={'secondary'} size={25} /> : t('save')}
									</Button>
									<Button
										variant="contained"
										onClick={() => {
											table.setCreatingRow(true); //simplest way to open the create row modal with no default values
											//or you can pass in a row object to set default values with the `createRow` helper function
											// table.setCreatingRow(
											// 	createRow(table, {
													//optionally pass in default values for the new row, useful for nested data or other complex scenarios
												// }),
											// );
										}}
									>
										Create New Item
									</Button>
								</Box>
							)}
							onCreatingRowSave={handleCreateRow}
							positionActionsColumn={"last"}
							renderRowActions={({ row }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<Tooltip title="Delete">
										<IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</Box>
							)}
							// renderTopToolbarCustomActions={({ table }) => (
							//
							// )}
							/*muiTableHeadCellProps={
								sx={
									padding: '16px 0',
									marginRight: '12px',
									color: '#268664',
									fontSize: '16px',
									fontWeight: 500,
									border: 'none',
									position: 'relative',
								}
							}
							muiTableHeadRowProps={
								sx={
								borderBottom: '1px solid #EDF2FA',
							}
						}*/
						/>
					</div>
				</div>
			)
			}

		</div>
	);
};

export default CashflowTable;