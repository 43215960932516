import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Market.scss';
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { getTam } from "../../http/listsAPI";
import { setTamAction } from "../../store/actions/listActions";
import { formatNumber } from "../../utils/formatDate";
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { pData, uData, xLabels } from "../../constants/constantsList";

export const ColorSwitch = () => {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;
  
  return (
    <>
      <defs>
        <linearGradient id="paint0_linear_3475_45009" x1="585.998" y1="70.6569" x2="585.998" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#1FF3CC" stopOpacity="0.5"/>
          <stop offset="1" stopColor="#4BAF9D" stopOpacity="0"/>
        </linearGradient>
      </defs>
      
      <defs>
        <linearGradient id="paint0_linear_3475_45006" x1="585.998" y1="92.0014" x2="585.998" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#75FBE2" stopOpacity="0.6"/>
          <stop offset="1" stopColor="#168D77" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </>
  )
}

const Market = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const tam = useSelector((state) => state.list.tam);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const itemWrapRef = useRef(null);
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await getTam();
      
      if (result.status === 200) {
        console.log("result: ", result);
        dispatch(setTamAction(result.data.tam));
        setLoading(false);
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, []);
  
  useEffect(() => {
    if (tam && tam.onboarded) {
      setData([
        { label: `Onboarded ${tam.onboarded}`, value: tam.onboarded, color: "#288B79" },
        { label: `Not onboarded ${tam.notOnboarded}`, value: tam.notOnboarded, color: "#4BAF9D" },
        { label: `Pending ${tam.pending}`, value: tam.pending, color: "#4BAF9D4C" },
      ])
    }
  }, [tam]);
  
  const StyledLabel = styled('text')(({ theme }) => ({
    fill: '#2B2C2F',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 14,
    fontWidth: '300',
  }));
  
  const StyledValue = styled('text')(({ theme }) => ({
    fill: '#2B2C2F',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 48,
    fontWidth: '300',
  }));
  
  /*let data = [
    { label: 'Onboarded', value: 40, color: "#288B79" },
    { label: 'Not onboarded', value: 160, color: "#4BAF9D" },
    { label: 'Pending', value: 200, color: "#4BAF9D4C" },
  ];*/
  
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <>
        <StyledLabel x={left + width / 2} y={65}>
          TAM
        </StyledLabel>
        <StyledValue x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledValue>
      </>
    );
  }
  
  return (
    <div className="market">
      <Breadcrumbs selectedItems={{}} />
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="charts__wrap">
          <div className="charts__item">
            <PieChart
              series={[
                {
                  paddingAngle: 5,
                  innerRadius: 68,
                  outerRadius: 80,
                  data,
                  cornerRadius: 20,
                  
                },
              ]}
              margin={{ right: 10 }}
              width={550}
              height={200}
              slotProps={{
                legend: {
                  position: {
                    vertical: 'middle',
                    horizontal: 'right',
                  },
                  itemMarkWidth: 8,
                  itemMarkHeight: 8,
                  markGap: 5,
                  itemGap: 10,
                }
              }}
              sx={{
                [`& .MuiChartsLegend-mark`]: {
                  ry: 10,
                },
              }}
              // legend={{ hidden: true }}
            >
              <PieCenterLabel>{tam && tam.tam ? formatNumber(tam.tam) : '0'}</PieCenterLabel>
            </PieChart>
          </div>
          <div className="charts__item" ref={itemWrapRef}>
            <LineChart
              width={itemWrapRef.current ? itemWrapRef.current.offsetWidth : 500}
              height={300}
              series={[
                { data: uData, label: 'uv', area: true, stack: 'total', showMark: false },
                { data: pData, label: 'pv', area: true, stack: 'total', showMark: false },
              ]}
              sx={{
                '.css-1a8zxh7-MuiAreaElement-root': {
                  fill: 'url(#paint0_linear_3475_45009)',
                },
                '.css-1la267r-MuiAreaElement-root': {
                  fill: 'url(#paint0_linear_3475_45006)',
                },
                '.css-a8rcf6-MuiLineElement-root': {
                  stroke: 'rgba(22,141,119,0.5)',
                },
                [`& .${lineElementClasses.root}`]: {
                  // display: 'none',
                },
                [`.MuiChartsAxis-directionY`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-line`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-tick`]: {
                  display: 'none',
                },
                [`& .MuiChartsLegend-series`]: {
                  display: 'none'
                },
              }}
              xAxis={[{ scaleType: 'point', data: xLabels }]}
              /*sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: 'none',
                },
              }}*/
            >
              <ColorSwitch />
            </LineChart>
          </div>
        </div>
      )}
    </div>
  );
};

export default Market;
