import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import './Sidebar.scss';
import { useSelector } from "react-redux";

const Sidebar = () => {
  const user = useSelector((state) => state.user);
  const [isOpenBurger, setIsOpenBurger] = useState(false);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const availableLanguages = Object.keys(i18n.options.resources);
  const [isLanguageOpened, setIsLanguageOpened] = useState(false);
  
  const lngRef = useRef(null);
  const isHidden = useMemo(() => {
    const regex = /^(\/customer\/|\/cleaner\/|\/cleaning\/|\/address\/|\/subscription\/|\/agent\/)/;
    return regex.test(pathname);
  }, [pathname]);
  
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (lngRef.current && !lngRef.current.contains(e.target)) {
        setIsLanguageOpened(false);
      }
    };
    
    if (isLanguageOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLanguageOpened]);
  
  const openLanguages = () => {
    setIsLanguageOpened((state) => !state);
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    document.documentElement.lang = lng;
    setIsLanguageOpened(false);
  };
  
  return (
    <div className={`page ${isHidden && 'reduced'}`}>
      <div className="background"></div>
      <div className="sidebar__burger" onClick={() => setIsOpenBurger(!isOpenBurger)}>
        <span className="sidebar__burger_icon">&nbsp;</span>
      </div>
      <div className="container side">
        <div className={`sidebar ${isOpenBurger ? 'active' : ''}`}>
          {
            width < 768 ? (
              <div className="sidebar__info">
                <div className="sidebar__account">
                  <span className="settings-modal__account">
                    {`${user.name.charAt(0).toUpperCase() + user.name.slice(1).toLowerCase()} ${
                      user.surname.charAt(0).toUpperCase() + user.surname.slice(1).toLowerCase()
                    }`}
                  </span>
                  <span className="settings-modal__role">{t('administrator')}</span>
                </div>
                <div className="language-wrap">
                  <div className={`language ${isLanguageOpened ? 'opened' : ''}`} ref={lngRef}>
                    <div className="language__selected" onClick={openLanguages}>
                      <span className="language__value">{language}</span>
                    </div>
                    <div className="language__variants">
                      {availableLanguages.filter((elem) => elem !== language).map((elem, index) => (
                        <span
                          key={index}
                          className={`language__value not-selected`}
                          onClick={() => changeLanguage(elem)}
                        >
                      {elem}
                    </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          
          {/*<span className="sidebar__project">{`${t('project')}:\u00A0SDL`}</span>*/}
          <ul className="sidebar__list">
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/">
                {t('dashboard')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${pathname.startsWith('/register') && 'active'}`} to="/cleaners">
                {t('cleaners')}
              </NavLink>
              {(pathname.startsWith('/cleaners') || pathname.startsWith('/register')) && (
                <ul className="sidebar__sublist">
                <li className="sidebar__item">
                  <NavLink
                    className={`sidebar__sublist-link ${pathname.startsWith('/register') ? 'active' : ''}`}
                    to="/register/form"
                  >
                    + {t('register')}
                  </NavLink>
                </li>
              </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/customers">
                {t('customers')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/reviews">
                {t('reviews')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/unconfirmed_requests') || pathname.startsWith('/cancelled_requests')) && 'active'}`} to="/requests">
                {t('requests')}
              </NavLink>
              {(pathname.startsWith('/requests') || pathname.startsWith('/unconfirmed_requests') || pathname.startsWith('/cancelled_requests')) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink className="sidebar__sublist-link" to="/unconfirmed_requests">
                      {t('unconfirmed')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink className="sidebar__sublist-link" to="/cancelled_requests">
                      {t('cancelled')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/adjustments') || pathname.startsWith('/cancelled_jobs')) && 'active'}`} to="/jobs">
                {t('jobs')}
              </NavLink>
              {(pathname.startsWith('/jobs') || pathname.startsWith('/adjustments') || pathname.startsWith('/cancelled_jobs')) && (
                <ul className="sidebar__sublist">
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/adjustments">
                    {t('adjustments')}
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/cancelled_jobs">
                    {t('cancelled')}
                  </NavLink>
                </li>
              </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/stayhost') || pathname.startsWith('/invoices')) && 'active'}`} to="/stayhost">
                {t('stayhost')}
              </NavLink>
              {(pathname.startsWith('/stayhost') || pathname.startsWith('/invoices')) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/stayhost') ? 'active' : ''}`}
                      to="/stayhost"
                    >
                      {t('stayhost')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/invoices') ? 'active' : ''}`}
                      to="/invoices"
                    >
                      {t('invoices')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${pathname.startsWith('/addresses') && 'active'}`} to="/addresses">
                {t('addresses')}
              </NavLink>
            </li>
            {/*<li className="sidebar__item">
              <NavLink className="sidebar__link" to="/contact_requests">
                {t('affiliate')}
              </NavLink>
            </li>*/}
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${pathname.startsWith('/add_agent') && 'active'}`} to="/agents">
                {t('realEstate')}
              </NavLink>
              {(pathname.startsWith('/agents') || pathname.startsWith('/add_agent')) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/add_agent') ? 'active' : ''}`}
                      to="/add_agent"
                    >
                      + {t('add')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/notifications">
                {t('notifications')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${
                (pathname.startsWith('/accounting-all')
                  ||
                  pathname.startsWith('/accounting-metrics')
                  ||
                  pathname.startsWith('/cashflow')
                ) && 'active'}`} to="/accounting-all">
                {t('accounting')}
              </NavLink>
              {(pathname.startsWith('/accounting')
                ||
                pathname.startsWith('/accounting-all')
                ||
                pathname.startsWith('/accounting-metrics')
                ||
                pathname.startsWith('/cashflow')
              ) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/accounting-all') ? 'active' : ''}`}
                      to="/accounting-all"
                    >
                      {t('accounting')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/accounting-metrics') ? 'active' : ''}`}
                      to="/accounting-metrics"
                    >
                      {t('metrics')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/cashflow') ? 'active' : ''}`}
                      to="/cashflow"
                    >
                      {t('cashflow')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            
          </ul>
        </div>
        <div className={`content ${isHidden && 'expanded'}`}>
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
