import React, { useEffect, useState } from 'react';
import { css } from "styled-components";
import { useTranslation } from "react-i18next";
import signImage from "./signImage.png";
import { format, subMonths } from "date-fns";
import { enUS, es } from "date-fns/locale";

const PdfListInvoiceTemplate = ({ data = [] }) => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const prevMonth = subMonths(new Date(), 1);
	
	useEffect(() => {
		console.log("data: ", data);
	}, [data]);

	return (
		<div id="pdfListInvoiceTemplate" className="pdfListInvoiceTemplate" {...css({
			backgroundColor: 'rgba(40,134,101,0.5)',
			width: '210mm',
			minHeight: '297mm',
			marginLeft: 'auto',
			marginRight: 'auto',
			boxSizing: 'border-box',
			padding: 16
		})}>
			<div className="pdfListInvoiceTemplate__content">
				<div className="pdfListInvoiceTemplate__content_title">
					<h1>SDL</h1>
					<p>Servicio de limpieza</p>
				</div>
				<div className="pdfListInvoiceTemplate__content_info">
					<div className="pdfListInvoiceTemplate__content_info_row fl">
						<span>First Luxury Realty Marbella, S.L</span>
					</div>
					<div className="pdfListInvoiceTemplate__content_info_row">
						<span>CIF/DNI: B72522485</span>
						<span>{t('socialSecurity')}: 29 147551723</span>
					</div>
					<div className="pdfListInvoiceTemplate__content_info_row">
						<div className="pdfListInvoiceTemplate__content_info_row_employee">
							<span>{t('employee')}: {}</span>
							<div className="pdfListInvoiceTemplate__content_info_row_employee_item">
								<span>{data.length > 0 && data[0].original && data[0].original.employee ? data[0].original.employee : '-'}</span>
								{/*<span>Yolanda</span>*/}
							</div>
						</div>
						<span>NIF/NIE: B72522485</span>
						<span>NAF: 290078578154</span>
					</div>
					<div className="pdfListInvoiceTemplate__content_info_row">
						<span>{t('month')}: {format(new Date(prevMonth), 'LLLL', { locale: language === 'en' ? enUS : es })}</span>
						<span>{t('year')}: {new Date(prevMonth).getFullYear()}</span>
					</div>
				</div>
				<div className="pdfListInvoiceTemplate__content_data">
					<div className="pdfListInvoiceTemplate__content_data_title">
						<span>{t('date')}</span>
						<span>{t('property')}</span>
						<span>{t('address')}</span>
						<span>{t('hours')}</span>
					</div>
					<div className="pdfListInvoiceTemplate__content_data_table">
						{
							data.length > 0 && data.map(item => {
								if (item.original) {
									return (
										<div key={item.id} className="pdfListInvoiceTemplate__content_data_table_item">
											<span>{item.original.date.substring(0, item.original.date.indexOf("."))}</span>
											<span>{item.original.property}</span>
											<span>{item.original.fullAddress}</span>
											<span>{item.original.shHours}</span>
										</div>
									)
								} else {
									return null
								}
							})
						}
					</div>
					<div className="pdfListInvoiceTemplate__content_data_total">
						<span>{t('total')}:</span>
						<span>{data.reduce((accumulator, current) => accumulator + current.original.shHours, 0)}</span>
					</div>
				</div>
				<div className="pdfListInvoiceTemplate__content_text">
					<span>{t('dailyRecord')}</span>
				</div>
				<div className="pdfListInvoiceTemplate__content_signature">
					<div className="pdfListInvoiceTemplate__content_signature_content">
						<span>{t('signature')}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PdfListInvoiceTemplate;