import React, { useEffect, useMemo, useRef, useState } from 'react';
import ListTable from "./ListTable/ListTable";
import { useTranslation } from "react-i18next";
import { reviewsCols } from "../../constants/tableColumns";
import { getAllCleanerFeedbacks } from "../../http/listsAPI";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  minHeight: 400,
  maxHeight: 900,
  overflow: 'scroll',
  bgcolor: '#fff',
  boxShadow: 24,
  borderRadius: 1,
  pt: 4,
  px: 4,
  pb: 4,
};

const Reviews = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllCleanerFeedbacks();
      
      if (result.status === 200) {
        console.log("getAllFeedbacks: ", result);
        if (result.data && result.data.reviews && result.data.reviews.length > 0) {
          setReviews(result.data.reviews);
        } else {
          setReviews([]);
        }
      }
    };
    
      fetchData().then(() => setLoading(false));
  }, []);
  
  const columns = useMemo(() => reviewsCols.map((item) => {
    if (item.header) {
      return { ...item, header: t(item.header.toLowerCase()) };
    }
    return item
  }), [t]);
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose(e);
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  
  const handleRowSelect = (row) => {
    if (row) {
      console.log("row:", row);
      setSelectedRow(row);
      setIsOpen(true);
    }
  };
  
  const handleModalClose = () => {
    setIsOpen(false);
    setSelectedRow({});
  }
  
  return (
    <div className="data reviews">
      <h2 className="reviews__title">{`${t('reviews')}`}</h2>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="data__table-wrap">
          <ListTable
            data={reviews}
            columns={columns}
            isClickable={true}
            isSelectable={false}
            onRowClick={handleRowSelect}
          />
        </div>
      )}
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          {
            selectedRow && (
              <div className="review__modal">
                <div className="review__modal_header">
                  <div className="review__modal_header_rooms">
                    <span>{t('living rooms')} {selectedRow.address?.livingRooms || 0}</span>
                    <span>{t('kitchens')} {selectedRow.address?.kitchens || 0}</span>
                    <span>{t('bedrooms')} {selectedRow.address?.bedrooms || 0}</span>
                    <span>{t('bathrooms')} {selectedRow.address?.bathrooms || 0}</span>
                    <span>{t('levelDirtiness')} {selectedRow?.dirtiness || 0}</span>
                  </div>
                  <span className="review__modal_header_text">{selectedRow?.date || ''} {selectedRow?.time || ''}</span>
                </div>
                <div className="review__modal_data">
                  <span className="review__modal_header_text">{selectedRow?.review || '-'}</span>
                </div>
                <div className="review__modal_images">
                  {
                    selectedRow?.images &&
                    selectedRow?.images?.length > 0 &&
                    selectedRow.images.map((image, index) => (
                      <div key={index} className="review__modal_images_item">
                        <img src={image} alt={image} />
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </Box>
        {/*<Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Text in a modal</h2>
          <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p>
          <ChildModal />
        </Box>*/}
      </Modal>
    </div>
  );
};

export default Reviews;