import React, { useEffect, useState } from 'react';
import { css } from "styled-components";
import { useTranslation } from "react-i18next";
import signImage from "./signImage.png";

const PdfListTemplate = ({ data = [] }) => {
	const { t } = useTranslation();

	useEffect(() => {
		console.log("data: ", data);
	}, [data]);

	return (
		<div id="pdfListTemplate" className="pdfListTemplate" {...css({
			backgroundColor: 'rgba(40,134,101,0.5)',
			width: '210mm',
			minHeight: '297mm',
			marginLeft: 'auto',
			marginRight: 'auto',
			boxSizing: 'border-box',
			padding: 16
		})}>
			<div className="pdfListTemplate__content">
				<div className="pdfListTemplate__content_title">
					<h1>SDL</h1>
					<p>Servicio de limpieza</p>
				</div>
				<div className="pdfListTemplate__content_data">
					<div className="pdfListTemplate__content_data_title">
						<span>{t('property')}</span>
						<span>{t('shhours')}</span>
						<span>{t('sdlhours')}</span>
						<span>{t('priceofcleaning')}</span>
						<span>{t('address')}</span>
					</div>
					<div className="pdfListTemplate__content_data_table">
						{
							data.length > 0 && data.map(item => {
								if (item.original) {
									return (
										<div key={item.id} className="pdfListTemplate__content_data_table_item">
											<span>{item.original.property}</span>
											<span>{item.original.shHours}</span>
											<span>{item.original.sdlHours}</span>
											<span>{item.original.priceOfCleanings}</span>
											<span>{item.original.fullAddress}</span>
										</div>
									)
								} else {
									return null
								}
							})
						}
					</div>
					<div className="pdfListTemplate__content_data_total">
						<div className="pdfListTemplate__content_data_total_content">
							<span>{t('totalProcessed')}:</span>
							<span>€{data.reduce((accumulator, current) => accumulator + current.original.priceOfCleanings, 0)}</span>
						</div>
						<div>
							<div className="pdfListTemplate__content_data_total_sign">
								<span>{t('signed')}</span>
								<img src={signImage} alt="Sign" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PdfListTemplate;