import $host from './index';

export const getAllCleaners = async () => {
  try {
    const data = await $host.get('api/profile/get_cleaners');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAllCustomers = async () => {
  try {
    const data = await $host.get('api/profile/get_customers');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAllJobs = async () => {
  try {
    const data = await $host.get('api/job/get_all_jobs_admin');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAdjustmentJobs = async () => {
  try {
    const data = await $host.get('api/job/get_adjustment_jobs');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getCancelledJobs = async () => {
  try {
    const data = await $host.get('api/job/get_cancelled_jobs');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const deleteSelectedJobs = async (jobIds) => {
  try {
    const data = await $host.post('api/job/delete_jobs', {
      jobIds
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    return { error: 'Unexpected error' };
  }
};

export const getAllOrders = async () => {
  try {
    const data = await $host.get('api/order/get_all_orders');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const changeOrderTime = async (id, newTime) => {
  try {
    const data = await $host.put('api/order/update_order_time', {
      id,
      newTime
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const deleteSelectedOrders = async (orderIds) => {
  try {
    const data = await $host.post('api/order/delete_orders', {
      orderIds
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getCancelledOrders = async () => {
  try {
    const data = await $host.get('api/order/get_cancelled_orders');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getContactRequests = async () => {
  try {
    const data = await $host.get('api/contact/get_requests');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const getAllAgents = async () => {
  try {
    const data = await $host.get('api/agent/get_agents');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const changeWorkingStatus = async (userId, workingStatus) => {
  try {
    const data = await $host.post('api/working_hours/change_working_status', {
      userId,
      workingStatus
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }

    return { error: 'Unexpected error' };
  }
};

export const changeJobStatus = async (jobId, status) => {
  try {
    const data = await $host.patch(`api/job/update_job/${jobId}`, {
      status
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const getAllAddresses = async () => {
  try {
    const data = await $host.get('api/address/get_all_addresses');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const getTam = async () => {
  try {
    const data = await $host.get('api/address/get_admin_tam');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const deleteUsers = async (userIds) => {
  try {
    console.log("userIds: ", userIds);
    const data = await $host.post('api/profile/delete_users', {
      userIds: userIds
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const addCashflowRow = async (newRow) => {
  try {
    const data = await $host.post('api/accounting/add_cashflow_row', newRow);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const getCashflow = async () => {
  try {
    const data = await $host.get('api/accounting/get_cashflow');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const updateCashflow = async (updatedRow) => {
  try {
    const data = await $host.put('api/accounting/update_cashflow', {
      updatedRow
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const deleteCashflowRow = async (id) => {
  try {
    const data = await $host.delete(`api/accounting/delete_cashflow/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};

export const getAllCleanerFeedbacks = async () => {
  try {
    const data = await $host.get('api/feedback/get_all_cleaner_feedbacks');
    return data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return { message: error.response.data.message, error: true };
    }
    
    return { error: 'Unexpected error' };
  }
};