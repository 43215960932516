import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Dashboard.scss';
import { useDispatch } from "react-redux";
import {
  getDashboardCalendar,
  getDashboardSchedule,
  getDashboardStatistics,
  getDashboardTotal
} from "../../http/pricingAPI";
import ScheduleItem from "./components/ScheduleItem";
import StatisticItem from "./components/StatisticItem";
import CalendarItem from "./components/CalendarItem";
import arrowLeftSvg from '../../images/arrow-left.svg';
import arrowRightSvg from '../../images/arrow-right.svg';
import CustomSelectedCalendar from "./components/CustomSelectedCalendar";
import moment from 'moment-timezone';

const Dashboard = () => {
  const { t } = useTranslation();
  const [expandedMain, setExpandedMain] = useState({
    'statistics': false,
    'calendar': false,
    'schedule': false,
    'total': false
  });
  const [statisticsData, setStatisticsData] = useState({});
  const [scheduleData, setScheduleData] = useState({});
  const [calendarData, setCalendarData] = useState({});
  const [totalData, setTotalData] = useState({});
  
  const [weekIndex, setWeekIndex] = useState(0);
  const [selectedStatisticsDates, setSelectedStatisticsDates] = useState({});
  const [selectedScheduleDates, setSelectedScheduleDates] = useState({});
  const [selectedTotalDates, setSelectedTotalDates] = useState({});
  
  const [loading, setLoading] = useState(true);
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  
  const dispatch = useDispatch();
  
  const toggleCategory = (category) => {
    setExpandedMain((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedStatisticsDates && selectedStatisticsDates.length > 1) {
        let startDate = new Date(selectedStatisticsDates[0]);
        let endDate = new Date(selectedStatisticsDates[1]);
        setLoading(true);
        
        console.log(`\nstartDate: ${startDate}\nendDate: ${endDate}`);
        
        const result = await getDashboardStatistics(startDate, endDate);
        if (result && result.status === 200 && result.data.statistics) {
          console.log("result statistic: ", result.data.statistics);
          setStatisticsData(result.data.statistics);
        } else {
          setStatisticsData({});
        }
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, [selectedStatisticsDates]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedScheduleDates && selectedScheduleDates.length > 1) {
        let startDate = new Date(selectedScheduleDates[0]);
        let endDate = new Date(selectedScheduleDates[1]);
        setLoadingSchedule(true);
        const result = await getDashboardSchedule(startDate, endDate);
        if (result && result.status === 200 && result.data.schedule) {
          console.log("result schedule: ", result.data.schedule);
          setScheduleData(result.data.schedule);
        } else {
          setScheduleData({});
        }
      }
    };
    
    fetchData().then(() => setLoadingSchedule(false));
  }, [selectedScheduleDates]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoadingCalendar(true);
      const result = await getDashboardCalendar(weekIndex);
      if (result && result.status === 200) {
        console.log("result.data: ", result.data.weeklyStats);
        setCalendarData(result.data.weeklyStats);
      } else {
        setCalendarData([]);
      }
    };
    
    fetchData().then(() => setLoadingCalendar(false));
  }, [weekIndex]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedTotalDates && selectedTotalDates.length > 1) {
        let startDate = new Date(selectedTotalDates[0]);
        let endDate = new Date(selectedTotalDates[1]);
        // console.log("selectedTotalDates: ", selectedTotalDates);
        // console.log("selectedTotalDates[0]: ", selectedTotalDates[0]);
        // console.log("new Date(selectedTotalDates[0]): ", new Date(selectedTotalDates[0]));
        
        const startDateISO = moment(selectedTotalDates[0])
          .tz("Europe/Madrid") // Устанавливаем временную зону
          .startOf('day') // Начало дня по временной зоне
          .add(1, 'hour')
          .utc() // Преобразуем в UTC
          .toISOString(); // Форматируем как ISO-дату
        
        const endDateISO = moment(selectedTotalDates[1])
          .tz("Europe/Madrid") // Устанавливаем временную зону
          .endOf('day') // Конец дня по временной зоне
          .add(1, 'hour')
          .utc() // Преобразуем в UTC
          .toISOString(); // Форматируем как ISO-дату
        
        console.log("startDateISO: ", startDateISO);
        console.log("endDateISO: ", endDateISO);
        
        setLoadingTotal(true);
        const result = await getDashboardTotal(startDateISO, endDateISO);
        if (result && result.status === 200 && result.data.total) {
          console.log("result total: ", result.data.total);
          setTotalData(result.data.total);
        } else {
          setTotalData({});
        }
      }
    };
    
    fetchData().then(() => setLoadingTotal(false));
  }, [selectedTotalDates]);
  
  const handlePreviousWeek = () => {
    setWeekIndex((prev) => prev - 1);
  };
  
  const handleNextWeek = () => {
    setWeekIndex((prev) => prev + 1);
  };
  
  return (
    <div className="accounting">
      <h2 className="accounting__title">{`${t('dashboard')}`}</h2>
      {Object.keys(expandedMain).map((category) => (
        <div key={category} className={`block ${expandedMain[category] ? 'expanded' : ''}`}>
          <div className={`card__title-wrap dashboard ${expandedMain[category] && category && category.replace(/[-_\s](.)/g, (_, c) => c.toUpperCase())}`}>
            <div className="card__title-item" onClick={() => toggleCategory(category)}>
              <svg
                className={expandedMain[category] ? 'arrow' : 'arrow rotated'}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 14L12 8L18 14" stroke="#268664" strokeLinecap="round" />
              </svg>
              <h2 className="card__title">{t(category)}</h2>
            </div>
            {
              expandedMain[category] && category === 'statistics' ? (
                <div className="card__title-perCleaner">
                  <CustomSelectedCalendar
                    setDate={setSelectedStatisticsDates}
                  />
                </div>
              ) : expandedMain[category] && category === 'calendar' ? (
                <div className="calendarWeeks__btns">
                  <button
                    onClick={handlePreviousWeek}
                  >
                    <img src={arrowLeftSvg} alt="arrow left icon" />
                  </button>
                  <button
                    onClick={handleNextWeek}
                  >
                    <img src={arrowRightSvg} alt="arrow right icon" />
                  </button>
                </div>
              ) : expandedMain[category] && category === 'schedule' ? (
                <div className="card__title-perCleaner">
                  <CustomSelectedCalendar
                    setDate={setSelectedScheduleDates}
                  />
                </div>
              ) : expandedMain[category] && category === 'total' ? (
                <div className="card__title-perCleaner">
                  <CustomSelectedCalendar
                    setDate={setSelectedTotalDates}
                  />
                </div>
              ) : null
            }
          </div>
          {
            statisticsData &&
            expandedMain[category] &&
              category === 'statistics' ?
                (
                  <div className="statistics__wrap">
                    <StatisticItem
                      title={t('revenue')}
                      value={statisticsData.revenue ?? 0}
                      data={statisticsData.revenueChart ?? []}
                      xLabels={statisticsData.xLabels ?? []}
                      isLoading={loading}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('totalCost')}
                      value={statisticsData.totalCost ?? 0}
                      data={statisticsData.totalCostChart ?? []}
                      xLabels={statisticsData.xLabels ?? []}
                      isLoading={loading}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('profit')}
                      value={statisticsData.profit ?? 0}
                      data={statisticsData.profitChart ?? []}
                      xLabels={statisticsData.xLabels ?? []}
                      isLoading={loading}
                      withThousand={true}
                      decimals={2}
                    />
                    <StatisticItem
                      title={t('profitMargin')}
                      value={statisticsData.profitMargin ?? 0}
                      data={statisticsData.profitMarginChart ?? []}
                      xLabels={statisticsData.xLabels ?? []}
                      isPercent={true}
                      isLoading={loading}
                      withThousand={false}
                      decimals={2}
                    />
                  </div>
                ) : null
          }
          {
            expandedMain[category] &&
            category === 'calendar' ?
              (
                <div className="calendar__wrap">
                  {
                    calendarData &&
                    calendarData.length > 0 ?
                    calendarData.map((item, index) => {
                      return (
                        <CalendarItem
                          key={`${item.date} - ${index}`}
                          index={index}
                          item={item}
                          isLoading={loadingCalendar}
                        />
                      )
                    }) : (
                        <div className="spinner spinner_small"></div>
                      )
                  }
                </div>
              ) : null
          }
          {
            scheduleData &&
            expandedMain[category] &&
            category === 'schedule' ?
                (
                  <div className="schedule__wrap">
                    <ScheduleItem
                      title={t('customers')}
                      value={scheduleData.numberOfCustomers ?? 0}
                      link={"customers"}
                      isLoading={loadingSchedule}
                    />
                    <ScheduleItem
                      title={t('cleaners')}
                      value={scheduleData.numberOfCleaners ?? 0}
                      link={"cleaners"}
                      isLoading={loadingSchedule}
                    />
                    <ScheduleItem
                      title={t('requests')}
                      value={scheduleData.requests ?? 0}
                      link={"requests"}
                      isLoading={loadingSchedule}
                    />
                    <ScheduleItem
                      title={t('activeJobs')}
                      value={scheduleData.activeJobs ?? 0}
                      link={"jobs"}
                      isLoading={loadingSchedule}
                    />
                    <ScheduleItem
                      title={t('averagePrice')}
                      value={scheduleData.averagePrice ?? 0}
                      link={"accounting"}
                      isLoading={loadingSchedule}
                    />
                  </div>
                ) : null
          }
          {
            totalData &&
            expandedMain[category] &&
            category === 'total' ?
              (
                <div className="total__wrap">
                  <div className="total__wrap_item">
                    <StatisticItem
                      title={t('jobs')}
                      value={totalData.jobs ?? 0}
                      data={totalData.jobsChart && totalData.jobsChart.length > 0 ? totalData.jobsChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('requests')}
                      value={totalData.requests ?? 0}
                      data={totalData.requestsChart && totalData.requestsChart.length > 0 ? totalData.requestsChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('scheduled')}
                      value={totalData.scheduled ?? 0}
                      data={totalData.scheduledChart && totalData.scheduledChart.length > 0 ? totalData.scheduledChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={`${t('expired')} / ${t('cancelled')}`}
                      value={totalData.cancelled ?? 0}
                      data={totalData.cancelledChart && totalData.cancelledChart.length > 0 ? totalData.cancelledChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                  </div>
                  <div className="total__wrap_item">
                    <StatisticItem
                      title={t('workingHoursCapacity')}
                      value={totalData.workingHoursCapacity ?? 0}
                      data={totalData.workingHoursCapacityChart && totalData.workingHoursCapacityChart.length > 0 ? totalData.workingHoursCapacityChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('workingHours')}
                      value={totalData.workingHours ?? 0}
                      data={totalData.workingHoursChart && totalData.workingHoursChart.length > 0 ? totalData.workingHoursChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('freeCapacity')}
                      value={totalData.freeCapacityValue ?? 0}
                      data={totalData.freeCapacityChart && totalData.freeCapacityChart.length > 0 ? totalData.freeCapacityChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                  </div>
                  <div className="total__wrap_item">
                    <StatisticItem
                      title={t('cleaners')}
                      value={totalData.cleaners ?? 0}
                      data={totalData.cleanersChart && totalData.cleanersChart.length > 0 ? totalData.cleanersChart : []}
                      xLabels={totalData.xLabels && totalData.xLabels.length > 0 ? totalData.xLabels : []}
                      isLoading={loadingTotal}
                      withThousand={true}
                      decimals={0}
                    />
                    <StatisticItem
                      title={t('averageWorkingHoursWeek')}
                      value={totalData.averageWorkingHoursPerWeek ?? 0}
                      data={totalData.averageWorkingHoursPerWeekChart &&
                      totalData.averageWorkingHoursPerWeekChart.length > 0 ?
                        totalData.averageWorkingHoursPerWeekChart
                        :
                        []
                      }
                      xLabels={totalData.xLabelsWeek && totalData.xLabelsWeek.length > 0 ? totalData.xLabelsWeek : []}
                      isLoading={loadingTotal}
                      withThousand={false}
                      decimals={2}
                    />
                    <StatisticItem
                      title={t('actualWorkingHoursWeek')}
                      value={totalData.actualWorkingHoursPerWeek ?? 0}
                      data={totalData.actualWorkingHoursPerWeekChart &&
                        totalData.actualWorkingHoursPerWeekChart.length > 0 ?
                          totalData.actualWorkingHoursPerWeekChart
                          :
                          []
                      }
                      xLabels={totalData.xLabelsWeek && totalData.xLabelsWeek.length > 0 ? totalData.xLabelsWeek : []}
                      isLoading={loadingTotal}
                      withThousand={false}
                      decimals={2}
                    />
                    <StatisticItem
                      title={t('averageJobsWeek')}
                      value={totalData.averageJobsPerWeek ?? 0}
                      data={totalData.averageJobsPerWeekChart &&
                      totalData.averageJobsPerWeekChart.length > 0 ?
                        totalData.averageJobsPerWeekChart
                        :
                        []
                      }
                      xLabels={totalData.xLabelsWeek && totalData.xLabelsWeek.length > 0 ? totalData.xLabelsWeek : []}
                      isLoading={loadingTotal}
                      withThousand={false}
                      decimals={2}
                    />
                  </div>
                </div>
              ) : null
          }
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
