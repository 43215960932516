import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './ListTable.scss';

const ListTable = ({
                     data,
                     columns,
                     isClickable = false,
                     onSelectItems = () => {},
                     isSelectable = false,
                     rows = null,
                     isJob = false,
                     onRowClick = () => {}
}) => {
  const [dataList, setDataList] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setDataList(data);
  }, [data]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#258664',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: '#258664',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#258664',
          },
        },
      },
    },
  });
  
  const table = useMaterialReactTable({
    columns: columns,
    data: dataList,
    rows: rows,
    columnFilterDisplayMode: 'popover',
    enableRowVirtualization: false,
    enableRowSelection: isSelectable,
    state: { rowSelection },
    onRowSelectionChange: (updaterOrValue) => setRowSelection(updaterOrValue),
    getRowId: (row) => isJob && row.jobId ? row.jobId : row.itemId ? row.itemId : '',
    enableSelectAll: isSelectable,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnResizing: true,
    muiPaginationProps: {
      color: 'primary',
      showRowsPerPage: false,
      shape: 'rounded',
      showFirstButton: true,
      showLastButton: true,
    },
    paginationDisplayMode: 'pages',
    initialState: { pagination: { pageSize: 10 } },
    muiTableBodyCellProps: {
      sx: {
        padding: '16px 0',
        marginRight: '12px',
        color: '#2B2C2F',
        fontSize: '16px',
        fontWeight: 500,
        border: 'none',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        height: '57px',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: '16px 0',
        marginRight: '12px',
        color: '#284657',
        fontSize: '16px',
        fontWeight: 500,
        border: 'none',
        position: 'relative',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        borderBottom: '1px solid #EDF2FA',
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      // console.log("row: ", row);
      return ({
        sx: {
          borderBottom: '1px solid #EDF2FA',
          cursor: isClickable ? 'pointer' : 'default',
          textDecoration: 'none',
          color: 'inherit',
        },
        component: isClickable ? Link : 'tr',
        to: isClickable ? `/${row.original.item}/${row.original.itemId}` : undefined,
        
        onClick: (e) => {
          const targetClass = e.target.className;
          
          const className = typeof targetClass === 'string' ? targetClass : targetClass.baseVal;
          
          if (className && className.includes('status-wrapper')) {
            e.stopPropagation();
            e.preventDefault();
          } else if (e.target.closest('.reviews')) {
            e.stopPropagation();
            e.preventDefault();
            console.log("row.original.item: ", row.original);
            onRowClick(row.original);
          }
          
          /*const review = document.querySelector('.reviews');
          if (review) {
            e.stopPropagation();
            e.preventDefault();
          }*/
        },
      })
    },
  });
  
  const getSelectedUserIds = () => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    const selectedRows = table.getSelectedRowModel().rows;
    
    const itemsToSelect = {
      ids: selectedIds,
      rows: selectedRows
    };
    
    console.log("itemsToSelect: ", itemsToSelect);
    onSelectItems(itemsToSelect);
  };
  
  useEffect(() => {
    getSelectedUserIds();
  }, [rowSelection]);

  return (
    <ThemeProvider theme={theme}>
      <div className="data__table">
        <MaterialReactTable table={table} />
      </div>
    </ThemeProvider>
  );
};

export default ListTable;
