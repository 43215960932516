import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCleaners } from '../../http/listsAPI';
import { setCleanersAction } from '../../store/actions/listActions';
import { cleanerCols } from '../../constants/tableColumns';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ListTable from './ListTable/ListTable';
import { useTranslation } from "react-i18next";

const Cleaners = () => {
  const { t } = useTranslation();
  const cleaners = useSelector((state) => state.list.cleaners);

  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllCleaners();

      if (result.status === 200) {
        dispatch(setCleanersAction(result.data.cleaners.reverse()));
        setLoading(false);
      }
    };

    if (cleaners.length === 0) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const columns = useMemo(() => cleanerCols.map((item) => {
    if (item.header) {
      return { ...item, header: t(item.header.toLowerCase()) };
    }
    return item;
  }), [t]);

  return (
    <div className="data cleaners">
      <Breadcrumbs selectedItems={selectedUsers} />
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="data__table-wrap">
          <ListTable data={cleaners} columns={columns} isClickable={true} isSelectable={true} onSelectItems={setSelectedUsers} />
        </div>
      )}
    </div>
  );
};

export default Cleaners;
