import React, { useEffect, useRef, useState } from 'react';
import Select from "react-dropdown-select";
import calendarSvg from '../../../../images/calendar.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from "react-i18next";

const DateRangeDropdown = ({ loading = true, setDate }) => {
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = useState([{
		label: 'Month',
		value: 'month',
	}]);
	const [showCalendar, setShowCalendar] = useState(false);
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);
	const [dateLabel, setDateLabel] = useState("Calendar");
	const calendarRef = useRef(null);
	
	const handleSelectChange = (values) => {
		const selectedValue = values[0]?.value;
		const today = new Date();
		let startDate = new Date();
		let endDate = new Date();
		
		switch (selectedValue) {
			case 'today':
				// startDate = endDate = today;
				setDate([startDate, endDate]);
				break;
			case 'week':
				startDate = new Date(today);
				endDate = new Date(today);
				endDate.setDate(today.getDate() - 7);
				setDate([startDate, endDate]);
				break;
			case 'month':
				endDate = new Date(today);
				startDate = new Date(today.getFullYear(), today.getMonth(), 1);
				setDate([startDate, endDate]);
				break;
			case 'calendar':
				setDateLabel('Calendar');
				setShowCalendar(true);
				break;
			default:
				setDate([today, today]);
		}
		
		setSelectedOption(values);
		// onChange(values);
	};
	
	useEffect(() => {
		function insertAfter(referenceNode, newNode) {
			referenceNode.parentNode.insertBefore(newNode, referenceNode);
		}
		let el = document.createElement("img");
		el.src = calendarSvg;
		el.className = "calendarSvg__icon";
		let dropdownInput = document.querySelector('.dateRange__dropdown .react-dropdown-select-content');
		let imgEl = document.querySelector('.dateRange__dropdown .calendarSvg__icon');
		
		if (dropdownInput && !imgEl) {
			insertAfter(dropdownInput, el);
		}
		handleSelectChange([{
			label: 'Month',
			value: 'month',
		}]);
	}, []);
	
	const handleDateChange = (ranges) => {
		const { selection } = ranges;
		setDateRange([selection]);
		setDate([selection.startDate, selection.endDate]);
		
		const formattedStartDate = formatInTimeZone(selection.startDate, 'Europe/Madrid', 'dd.MM.yyyy');
		const formattedEndDate = formatInTimeZone(selection.endDate, 'Europe/Madrid', 'dd.MM.yyyy');
		const newLabel = `${formattedStartDate} -> ${formattedEndDate}`;
		
		setDateLabel(newLabel);
		setSelectedOption([{ label: newLabel, value: 'calendar' }]);
		setShowCalendar(false);
	};
	
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (calendarRef.current && !calendarRef.current.contains(e.target)) {
				setShowCalendar(false);
			}
		};
		
		if (showCalendar) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showCalendar]);
	
	return (
		<div className="date_range">
			<Select
				loading={false}
				multi={false}
				options={[
					{ label: t('today'), value: 'today' },
					{ label: t('week'), value: 'week' },
					{ label: t('month'), value: 'month' },
					{ label: dateLabel, value: 'calendar' },
				]}
				onChange={handleSelectChange}
				values={selectedOption}
				className="dateRange__dropdown"
				color={"#268664"}
				selectAll={false}
			/>
			<div
				ref={calendarRef}
			>
				{showCalendar && (
					<DateRange
						className="dateRange__calendar"
						ranges={dateRange}
						onChange={handleDateChange}
						moveRangeOnFirstSelection={false}
						showDateDisplay={false}
						rangeColors={['#4BAF9D']}
					/>
				)}
			</div>
		</div>
	);
};

export default DateRangeDropdown;
