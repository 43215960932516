import React from 'react';
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import AnimateShowNumber from "./AnimateShowNumber";

export const ColorSwitch = () => {
  return (
    <defs>
      <linearGradient
        id="paint0_linear_3916_75040"
        x1={0}
        y1={0}
        x2={0}
        y2={`93px`}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0.14}
          stopColor="#75FBE2"
          stopOpacity={0.5}
        />
        <stop
          offset={1}
          stopColor="#168D77"
          stopOpacity={0.1}
        />
      </linearGradient>
    </defs>
  )
}

const StatisticItem = ({
                         title,
                         value,
                         percent = 0,
                         data,
                         xLabels,
                         isLoading,
                         isPercent = false,
                         withThousand = true,
                         decimals = 3
}) => {
  const chartsParams = {
    margin: { bottom: 0, left: 0, right: 0, top: 0 },
    height: 93,
  };
  return (
    <div className="statisticItem">
      <div className="statisticItem__title">
        <span>{title}</span>
      </div>
      <div className="statisticItem__value">
        {
          isLoading ? (
            <div className="spinner spinner_small"></div>
          ) : (
            <AnimateShowNumber
              endNumber={value}
              time={800}
              withThousand={withThousand}
              decimals={decimals}
              isPercent={isPercent}
            />
          )
        }
      </div>
      <div className="statisticItem__chart">
        {
          data && data.length > 0 && xLabels && xLabels.length > 0 ? (
            <LineChart
              {...chartsParams}
              series={[
                {
                  curve: 'linear',
                  data: data,
                  label: title,
                  area: true,
                  stack: 'total',
                  showMark: false
                },
              ]}
              sx={{
                '.MuiAreaElement-root': {
                  // fill: 'linear-gradient(0deg, rgba(117, 251, 226, 0.10) 13.09%, rgba(22, 141, 119, 0.20) 93.51%)',
                  
                  fill: 'url(#paint0_linear_3916_75040)', // Применение градиента
                },
                '.MuiLineElement-root': {
                  stroke: 'rgba(22, 141, 119, 0.50)', // Цвет линии
                  strokeWidth: 1.2, // Толщина линии
                },
                '.css-1a8zxh7-MuiAreaElement-root': {
                  // fill: 'url(#paint0_linear_3475_45009)',
                },
                '.css-1la267r-MuiAreaElement-root': {
                  // fill: 'url(#paint0_linear_3475_45006)',
                },
                '.css-a8rcf6-MuiLineElement-root': {
                  stroke: 'rgba(22,141,119,0.5)',
                },
                [`& .${lineElementClasses.root}`]: {
                  // display: 'none',
                },
                [`.MuiChartsAxis-directionY`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-line`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-tick`]: {
                  display: 'none',
                },
                [`& .MuiChartsLegend-series`]: {
                  display: 'none'
                },
              }}
              xAxis={[{ scaleType: 'point', data: xLabels }]}
              /*sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: 'none',
                },
              }}*/
            >
              <ColorSwitch />
            </LineChart>
          ) : null
        }
      </div>
    </div>
  );
};

export default StatisticItem;