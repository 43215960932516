import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../Accounting.scss';
import { pData, uData, xLabels } from "../../../../constants/constantsList";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";

const ColorSwitch = () => {
  return (
    <defs>
      <linearGradient
        id="paint0_linear_3916_75040"
        x1={0}
        y1={0}
        x2={0}
        y2={`140px`}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0.14}
          stopColor="#75FBE2"
          stopOpacity={0.5}
        />
        <stop
          offset={1}
          stopColor="#168D77"
          stopOpacity={0.1}
        />
      </linearGradient>
    </defs>
  )
}

const KPIMetrics = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const tam = useSelector((state) => state.list.tam);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const itemWrapRef = useRef(null);
  
  const chartsParams = {
    margin: { bottom: 0, left: 0, right: 0, top: 0 },
    height: 140,
  };
  
  return (
    <div className="customersKpi">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="charts__wrap">
          <div className="charts__item" ref={itemWrapRef}>
            <LineChart
              {...chartsParams}
              series={[
                { data: uData, label: 'uv', area: true, stack: 'total', showMark: false },
                { data: pData, label: 'pv', area: true, stack: 'total', showMark: false },
              ]}
              sx={{
                '.MuiAreaElement-root': {
                  fill: 'url(#paint0_linear_3916_75040)',
                },
                '.MuiLineElement-root': {
                  stroke: 'rgba(22, 141, 119, 0.50)',
                  strokeWidth: 1.2,
                },
                [`.MuiChartsAxis-directionY`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-line`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-tick`]: {
                  display: 'none',
                },
                [`& .MuiChartsLegend-series`]: {
                  display: 'none'
                },
              }}
              xAxis={[{ scaleType: 'point', data: xLabels }]}
              /*sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: 'none',
                },
              }}*/
            >
              <ColorSwitch />
            </LineChart>
          </div>
        </div>
      )}
    </div>
  );
};

export default KPIMetrics;
